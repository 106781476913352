
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { Claim } from "@/modules/claims/claim.model";
import { getDictionary } from "@/api/dictionaries.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getBatchResponces } from "@/api/billingEvent.api";
import { saveWaystar } from "@/api/waystar.api";
import { getClaim } from "@/api/claim.api";

export interface Data {
  isDirty: boolean;
  claim?: Claim;
  batchResponses: [];
  dict: {
    cdrEvents: { code: string; description: string }[];
  };
}

export default defineComponent({
  name: "ServicesComponent",
  props: ["claim", "claimId"],
  components: {},
  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      claim: undefined,
      batchResponses: [],
      dict: {
        cdrEvents: [],
      },
    });

    onMounted(async () => {
      data.claim = await getClaim(props.claimId);
      await getDict();
      data.batchResponses = await getBatchResponces(props.claimId);
      organizationId.value = getOrganization();
    });

    async function getDict() {
      data.dict.cdrEvents = await getDictionary(12, "");
    }

    function getCdrEvent(cdr) {
      return data.dict.cdrEvents.find((item) => {
        return item.code == cdr;
      })?.description;
    }

    async function save() {
      const res = await saveWaystar();
      if (res) {
        data.batchResponses = await getBatchResponces(props.claimId);
        Swal.fire({
          title: "Import completed",
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }
    }

    return {
      getCdrEvent,
      organizationId,
      save,
      data,
    };
  },
});
